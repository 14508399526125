import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup:`}</p>
    <p>{`BB OH Lunge 4×5/leg (empty bar)`}</p>
    <p>{`Snatch Balance 4×5 (empty bar)`}</p>
    <p>{`12:00 EMOM of:`}</p>
    <p>{`1-Power Snatch (during the first :30 of each minute)`}</p>
    <p>{`1-Full Snatch (during the second :30 of each minute)`}</p>
    <p><em parentName="p">{`*`}{`using approximately 70% 1RM of your lightest movement`}</em></p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15-OHS (95/65)`}</p>
    <p>{`200ft Shuttle Run (100 down, 100 back)`}</p>
    <p>{`15-T2B`}</p>
    <p>{`200ft Shuttle Run`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Pop Up Mobility classes with Glenn will start next Wednesday,
March 6th, at 11:30am and Sunday, March 10th, at 12:00am.  This is free
for all Unlimited members, \\$10 for anyone else, so pop in if you can
make it!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You can now schedule a massage with Jennifer May, our newest member
and massage therapist! Jennifer is offering a special for the month of
February. You can get a full hour massage for \\$50!! The massage room is
located on the left as soon as you walk into the gym. She works by
appointment only, so email her to schedule.
 `}<a parentName="em" {...{
            "href": "mailto:j.zenmassagetherapy@gmail.com"
          }}>{`j.zenmassagetherapy@gmail.com`}</a>{`.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      